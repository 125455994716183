import { ActivityCard } from "./ActivityCard";
import { ActivityStatusButtons } from "./ActivityStatus";
import { Activity, Status } from "../../../types";

type Props = {
  activities: Activity[];
  visitStatus: Status;
  visitInstanceId: string;
  readOnly?: boolean;
};

export const Activities = ({
  activities,
  visitStatus,
  visitInstanceId,
  readOnly,
}: Props) => {
  return (
    <div className="pb-18 flex grow flex-col gap-8 px-5 pt-2">
      {activities.map((activity) => {
        return (
          <div key={activity.title} className="flex flex-col gap-2">
            <ActivityCard
              priority={activity.priority}
              title={activity.title}
              description={activity.description}
              start={activity.timeOfDayStart}
            />
            {visitStatus !== "pending" && !readOnly && (
              <ActivityStatusButtons
                status={activity.status}
                activityId={activity.id}
                visitInstanceId={visitInstanceId}
                visitStatus={visitStatus}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
