import { DateTime } from "luxon";
import { useTranslate } from "@tolgee/react";
import Linkify from "linkify-react";
import { PriorityIcon } from "@frontend/lyng/common/Priority";
import { Priority } from "../../../api/generated/graphql";
import { formatTime } from "../../../utils/dateUtils";

type Props = {
  priority: Priority;
  title: string;
  description: string;
  start?: DateTime;
};

const getIconAndText = (priority: Priority) => {
  if (priority === Priority.High)
    return {
      icon: <PriorityIcon className="mr-1" priority={Priority.High} />,
      text: "priorities.high",
    };
  if (priority === Priority.Medium)
    return {
      icon: <PriorityIcon className="mr-1" priority={Priority.Medium} />,
      text: "priorities.medium",
    };
  if (priority === Priority.Low)
    return {
      icon: <PriorityIcon className="mr-1" priority={Priority.Low} />,
      text: "priorities.low",
    };
  return { icon: null, text: null };
};

export const ActivityCard = ({
  priority,
  title,
  description,
  start,
}: Props) => {
  const { t } = useTranslate();

  const { icon, text } = getIconAndText(priority);

  return (
    <div className="text-title">
      <p className="text-xl font-bold leading-8">{title}</p>
      <div className="flex items-center gap-1">
        {icon}
        {start && (
          <p className="text-xs text-subtitle">{formatTime(start, false)} -</p>
        )}
        {text && <p className="text-xs text-subtitle">{t(text)} -</p>}
      </div>
      <p className="whitespace-pre-line pt-4">
        <Linkify
          options={{
            className: "text-primary-90 font-semibold break-all",
            target: "_blank",
          }}
        >
          {description}
        </Linkify>
      </p>
    </div>
  );
};
