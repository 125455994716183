import { ErrorResponse } from "@apollo/client/link/error";
import { useCallback } from "react";
import * as Sentry from "@sentry/react";

import ApolloProvider from "./api/ApolloProvider";
import { fetchAuthSession, signOut } from "aws-amplify/auth";

import AppRoot from "./AppRoot";

import initI18n from "./i18n";
import { TolgeeProvider } from "@tolgee/react";
import { Splash } from "./components/core/Splash";

import { CommsContextProvider } from "@frontend/lyng/comms";

const gatewayUri = import.meta.env.VITE_DOMAIN + "/graphql";
function App() {
  const getAccessToken: () => Promise<string | Error> =
    useCallback(async () => {
      return await fetchAuthSession().then((session) => {
        if (!session?.tokens?.accessToken) {
          return Promise.reject(Error("No session found"));
        }
        const token = session.tokens?.accessToken;

        // Refresh token if it's about to expire in 5 minutes
        if (
          !token.payload.exp ||
          token.payload.exp * 1000 - 60000 * 5 < Date.now()
        ) {
          return fetchAuthSession({ forceRefresh: true }).then((session) => {
            if (!session?.tokens?.accessToken) {
              return Promise.reject(
                Error("Failed to refresh: No session found"),
              );
            }
            return session.tokens.accessToken.toString();
          });
        }

        return token.toString();
      });
    }, []);
  const tolgee = initI18n();

  return (
    <CommsContextProvider>
      <TolgeeProvider tolgee={tolgee} fallback={<Splash />}>
        <ApolloProvider
          uri={gatewayUri}
          onErrorResponse={(error: ErrorResponse | null) => {
            console.error("Apollo error", error);
            if (
              !error ||
              !error?.networkError ||
              !("statusCode" in error.networkError)
            ) {
              return;
            }

            if (
              error.networkError.statusCode === 401 ||
              error.networkError.statusCode === 403
            ) {
              localStorage.removeItem("tenantId");
              signOut();
              return;
            }

            if (error.networkError.statusCode >= 400) {
              Sentry.captureException(error.networkError);
            }
          }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onStartOperation={() => {}}
          tokenRequest={() => getAccessToken()}
        >
          {() => <AppRoot />}
        </ApolloProvider>
      </TolgeeProvider>
    </CommsContextProvider>
  );
}

export default App;
